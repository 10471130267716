import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

import { ICustomerUpdateFormType } from "./form-type";
import { updateCustomerValidationSchema } from "./validation";
import { customerSchema } from "./schema";

import FormText from "@/components/kit/FormGenerator/FormText";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormCountryCode from "@/components/kit/FormGenerator/FormCountryCode";
import DashboardContent from "@/components/app/DashboardContent";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { IParams } from "@/types/params";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { useCustomerBlockAction, useCustomerById, useCustomerUpdate } from "@/api/hooks/useCustomer";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

const convertPhoneNumberToCodeAndPhone = (string: string) => {
    const phoneNumberStr = string.replace(/ /g, "");
    const withDash = phoneNumberStr.includes("-");

    const phoneNumber = withDash ? phoneNumberStr.split("-")[1] : phoneNumberStr.substring(4, phoneNumberStr.length);
    const countryCode = string && withDash ? phoneNumberStr.split("-")[0] : string.substring(0, 4);

    let finalCC = countryCode;

    if (countryCode.includes("+")) {
        finalCC = countryCode.split("+")[1];
    }

    return { countryCode: finalCC, phone: phoneNumber };
};

const CustomerForm: React.FC = () => {
    const { id } = useParams<IParams>();

    const navigate = useNavigate();
    const listAccess = useCheckPermissionAccess(PagesAccess.USER, OperationAccess.LIST);

    const { data: customerData, isLoading } = useCustomerById(Number(id));
    const { mutate: blockCustomer, isPending: blockLoading } = useCustomerBlockAction();
    const { mutate: updateCustomer, isPending: submitLoading } = useCustomerUpdate();

    const {
        control,
        reset,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<ICustomerUpdateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(customerSchema),
        mode: "all",
        resolver: yupResolver(updateCustomerValidationSchema),
    });

    useEffect(() => {
        if (customerData) {
            const { firstName, lastName, email, phoneNumber } = customerData.data;
            const { phone, countryCode } = phoneNumber
                ? convertPhoneNumberToCodeAndPhone(phoneNumber)
                : { countryCode: "", phone: "" };

            reset({ email, lastName, firstName, phone, countryCode });
        }
    }, [customerData, reset]);

    const onSubmit = async (_val: ICustomerUpdateFormType) => {
        updateCustomer({ id: Number(id), body: _val });
    };

    const handleBlock = async (id: number, checked: boolean) => {
        const handleAction = async () => {
            blockCustomer({ id, body: { active: !checked } });
        };

        if (checked) {
            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: "Block Confirmation",
                text: "Are you sure to block this customer?",
                handleAction,
            });
        } else {
            handleAction();
        }
    };

    if (!customerData) return null;

    const isBlocked = !customerData?.data.active;

    return (
        <>
            <DashboardHeader
                title="Update Customer"
                titleSuffix={
                    <Stack direction={"row"} spacing={2}>
                        {!isLoading ? (
                            <LoadingButton
                                color={isBlocked ? "primary" : "error"}
                                disabled={blockLoading}
                                loading={blockLoading}
                                variant="contained"
                                onClick={() => handleBlock(Number(id), !isBlocked)}
                            >
                                {isBlocked ? "Unblock" : "Block"} Customer
                            </LoadingButton>
                        ) : null}
                        {listAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.ABSOLUTE}>
                                Back To Customer List
                            </Button>
                        ) : null}
                    </Stack>
                }
            />
            <DashboardContent>
                <form
                    style={{ maxWidth: "450px", margin: "0 auto", padding: "0 0 2em 0" }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                            <Controller
                                key="firstName"
                                control={control}
                                name="firstName"
                                render={({ field }) => (
                                    <FormText
                                        className="firstName field"
                                        errorMessage={errors["firstName"] as FieldError}
                                        field={{ ...field }}
                                        label="First Name"
                                        placeholder="Enter First Name"
                                        props={{ required: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Controller
                                key="lastName"
                                control={control}
                                name="lastName"
                                render={({ field }) => (
                                    <FormText
                                        className="lastName field"
                                        errorMessage={errors["lastName"] as FieldError}
                                        field={{ ...field }}
                                        label="Last Name"
                                        placeholder="Enter Last Name"
                                        props={{ required: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Controller
                                key="email"
                                control={control}
                                name="email"
                                render={({ field }) => (
                                    <FormText
                                        className="email field"
                                        errorMessage={errors["email"] as FieldError}
                                        field={{ ...field }}
                                        label="Email"
                                        placeholder="Enter Email Address"
                                        props={{ required: true, type: "email" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Controller
                                key="countryCode"
                                control={control}
                                name="countryCode"
                                render={({ field }) => (
                                    <FormCountryCode
                                        className="countryCode field"
                                        errorMessage={errors["countryCode"] as FieldError}
                                        field={{ ...field }}
                                        placeholder="Enter Country Code"
                                        props={{ disabled: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Controller
                                key="phone"
                                control={control}
                                name="phone"
                                render={({ field }) => (
                                    <FormText
                                        className="phone field"
                                        errorMessage={errors["phone"] as FieldError}
                                        field={{ ...field }}
                                        label="Phone Number"
                                        placeholder="Enter Phone Number"
                                        props={{ disabled: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                className="loading-btn"
                                disabled={!isValid || submitLoading}
                                type="submit"
                                variant="contained"
                            >
                                {id ? "Update" : "Create"} User
                            </LoadingButton>{" "}
                            <Button type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DashboardContent>
        </>
    );
};

export default CustomerForm;
