import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { IUserCreateFormType } from "./form-type";
import { createUserValidationSchema } from "./validation";

import { USER_ROLE } from "@/constants/enums/user";
import FormSelect from "@/components/kit/FormGenerator/FormSelect";
import FormCountryCode from "@/components/kit/FormGenerator/FormCountryCode";
import FormText from "@/components/kit/FormGenerator/FormText";
import { userService } from "@/api/services/user";
import { useUser } from "@/context/authContext";

const defaultValues: IUserCreateFormType = {
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phone: "",
    role: "",
};

const convertPhoneNumberToCodeAndPhone = (string: string) => {
    const phoneNumberStr = string.replace(/ /g, "");
    const withDash = phoneNumberStr.includes("-");

    const phoneNumber = withDash ? phoneNumberStr.split("-")[1] : phoneNumberStr.substring(4, phoneNumberStr.length);
    const countryCode = string && withDash ? phoneNumberStr.split("-")[0] : string.substring(0, 4);

    let finalCC = countryCode;

    if (countryCode.includes("+")) {
        finalCC = countryCode.split("+")[1];
    }

    return { countryCode: finalCC, phone: phoneNumber };
};

export default function AddUserForm({
    onSubmit,
    loading,
}: {
    loading: boolean,
    // eslint-disable-next-line no-unused-vars
    onSubmit: (data: IUserCreateFormType) => void,
}) {
    const { id } = useParams();
    const navigate = useNavigate();

    const user = useUser();
    const isAdministrator = user?.role === USER_ROLE.SUPERADMIN;
    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        trigger,
    } = useForm<IUserCreateFormType>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(createUserValidationSchema),
    });

    // this code is very important - it's like refresh button for react-hook-form, make it update.
    useEffect(() => {
        if (isValid) void trigger();
    }, [trigger, isValid]);

    useEffect(() => {
        const getData = async (id: number) => {
            const response = await userService.show(Number(id));
            const { email, lastName, firstName, role, phoneNumber } = response.data.data;

            const { phone, countryCode } = phoneNumber
                ? convertPhoneNumberToCodeAndPhone(phoneNumber)
                : { countryCode: "", phone: "" };

            reset({ email, lastName, firstName, role, phone, countryCode });
        };

        if (id) {
            getData(Number(id));
        }
    }, [reset, isAdministrator, id]);

    const Roles = Object.entries(USER_ROLE).map(([, label]) => ({ label, value: label }));
    const NoAdminInRles = Roles.filter((role) => role.value !== USER_ROLE.SUPERADMIN);

    return (
        <form style={{ maxWidth: "450px", margin: "0 auto", padding: "0 0 2em 0" }} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="firstName"
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                            <FormText
                                className="firstName field"
                                errorMessage={errors["firstName"] as FieldError}
                                field={{ ...field }}
                                label="First Name"
                                placeholder="Enter First Name"
                                props={{ required: true }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="lastName"
                        control={control}
                        name="lastName"
                        render={({ field }) => (
                            <FormText
                                className="lastName field"
                                errorMessage={errors["lastName"] as FieldError}
                                field={{ ...field }}
                                label="Last Name"
                                placeholder="Enter Last Name"
                                props={{ required: true }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="email"
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <FormText
                                className="email field"
                                errorMessage={errors["email"] as FieldError}
                                field={{ ...field }}
                                label="Email"
                                placeholder="Enter Email Address"
                                props={{ required: true, disabled: !!id, type: "email" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Controller
                        key="countryCode"
                        control={control}
                        name="countryCode"
                        render={({ field }) => (
                            <FormCountryCode
                                className="countryCode field"
                                errorMessage={errors["countryCode"] as FieldError}
                                field={{ ...field }}
                                placeholder="Enter Country Code"
                            />
                        )}
                    />
                </Grid>
                <Grid item md={8} xs={12}>
                    <Controller
                        key="phone"
                        control={control}
                        name="phone"
                        render={({ field }) => (
                            <FormText
                                className="phone field"
                                errorMessage={errors["phone"] as FieldError}
                                field={{ ...field }}
                                label="Phone Number"
                                placeholder="Enter Phone Number"
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="role"
                        control={control}
                        name="role"
                        render={({ field }) => (
                            <FormSelect
                                className="role field"
                                data={isAdministrator ? Roles : NoAdminInRles}
                                errorMessage={errors["role"] as FieldError}
                                field={{ ...field }}
                                label="Role "
                                placeholder="Enter Role"
                                props={{ required: true, disabled: !isAdministrator }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton className="loading-btn" disabled={!isValid || loading} type="submit" variant="contained">
                        {id ? "Update" : "Create"} User
                    </LoadingButton>{" "}
                    <Button type="button" onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
